import { Box, Button, Grid, List, ListItem, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import useResponsive from '@/components/hooks/useResponsive';
import { SxClassProps } from '@/types';

import howItWorksImageDT from '@/../public/rebranding/how-it-works-large.jpg';
import howItWorksImageMD from '@/../public/rebranding/how-it-works-md.jpg';
import howItWorksImageMW from '@/../public/rebranding/how-it-works-mobile.jpg';
import { VERTICALS_NAMES } from '@/constants';
import { HOW_IT_WORKS_INSTRUCTIONS } from '@/components/pages/visitorHome/VisitorHomeRebrandLayout/constants';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import { AnalyticsEventName, CtaLocation, CtaText, ScreenName } from '@/types/enums';

const classes: SxClassProps = {
  container: {
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    backgroundColor: '#FAF7F7',
  },
  imageContainer: {
    height: {
      xs: '277px',
      sm: '401px',
      md: '669px',
    },
    width: {
      sm: '100%',
    },
    maxWidth: {
      md: '50%',
      lg: '54%',
    },
    backgroundImage: {
      xs: `url(${howItWorksImageMW.src})`,
      sm: `url(${howItWorksImageMD.src})`,
      lg: `url(${howItWorksImageDT.src})`,
    },
    backgroundPosition: {
      xs: 'center',
      sm: '10%',
      md: '40%',
      lg: 'top right',
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: {
      sm: 'care.white',
      md: '#FAF7F7',
    },
  },
  contentContainer: (theme) => ({
    height: 'auto',
    width: '100%',
    paddingY: '32px',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      height: '669px',
      width: '50%',
      paddingX: '55px',
    },

    [theme.breakpoints.up('lg')]: {
      width: '46%',
    },
  }),
  content: {
    boxSizing: 'content-box',
    marginX: 'auto',
    paddingX: {
      xs: 3,
    },
    maxWidth: {
      xs: '500px',
      sm: '560px',
      md: '513px',
    },
  },
  headline: {
    marginTop: '0',
    marginBottom: '32px',
    fontSize: {
      xs: '28px',
      md: '36px',
    },
    lineHeight: {
      xs: '34px',
    },
  },
  highlight: (theme) => ({
    display: 'inline-block',
    width: 'fit-content',
    marginY: '0',
    padding: '4px',
    borderRadius: '8px',
    backgroundColor: theme.palette.care?.blue[100],
    fontSize: {
      xs: '28px',
      md: '36px',
    },
    lineHeight: {
      xs: '34px',
    },
  }),
  list: {
    width: {
      sm: '450px',
      md: '100%',
    },
    marginBottom: {
      xs: '27px',
      sm: '32px',
    },
    '> .MuiListItem-root': {
      marginBottom: '32px',
    },
    'li:first-child p:first-child': {
      paddingRight: {
        xs: '6px',
      },
    },
    '& li': {
      paddingY: '0',
    },
  },
  listNumber: {
    marginY: '0',
    marginRight: '16px',
    color: '#3B71AA',
  },
};

interface HowItWorksProps {
  hasLoaded?: boolean;
  setOpenModalOrDrawer: Dispatch<SetStateAction<boolean>>;
}

function HowItWorks({ hasLoaded, setOpenModalOrDrawer }: HowItWorksProps) {
  const { isMobile, isMedium } = useResponsive();

  const handleGetStarted = () => {
    AnalyticsHelper.logEvent({
      name: AnalyticsEventName.CTA_INTERACTED_VHP,
      data: {
        screen_name: ScreenName.VISITOR_HOMEPAGE,
        enrollment_initiated: true,
        cta_text: CtaText.GET_STARTED,
        cta_location: CtaLocation.VHP_BODY,
        vertical: VERTICALS_NAMES.unknown,
      },
    });

    setOpenModalOrDrawer(true);
  };

  const copyHighlightOne = (() => {
    if (isMobile || isMedium) {
      return 'finding care ';
    }

    return 'finding care easier';
  })();

  return (
    <Grid container sx={classes.container}>
      {hasLoaded && <Grid item sx={classes.imageContainer} data-testid="how-it-works-image" />}
      <Grid container item sx={classes.contentContainer} direction="column" justifyContent="center">
        <Box sx={classes.content}>
          <Box sx={classes.headline}>
            <Box pb={{ md: 1, lg: 0 }}>
              <Typography variant="display3" component="span" sx={classes.headline}>
                We make{' '}
              </Typography>

              <Typography variant="display3" component="span" sx={classes.highlight}>
                {copyHighlightOne}{' '}
              </Typography>
            </Box>

            {(isMobile || isMedium) && (
              <Typography variant="display3" component="span" sx={classes.highlight}>
                easier
              </Typography>
            )}
          </Box>
          <List sx={classes.list}>
            {HOW_IT_WORKS_INSTRUCTIONS.map((instruction) => (
              <ListItem disableGutters alignItems="flex-start" key={instruction.stepNumber}>
                <Typography variant="display3" sx={classes.listNumber}>
                  {instruction.stepNumber}
                </Typography>
                <Box>
                  <Typography variant={!isMobile ? 'h2' : 'h3'} mb={1}>
                    {instruction.headline}
                  </Typography>
                  <Typography variant={!isMobile ? 'body1' : 'body2'}>
                    {instruction.subHeadline}
                  </Typography>
                </Box>
              </ListItem>
            ))}
          </List>
          <Button
            size="large"
            fullWidth={isMobile}
            variant="contained"
            color="secondary"
            onClick={handleGetStarted}>
            Get started
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

export default HowItWorks;
